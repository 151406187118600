const getDefaultState = () => {
  return {
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {},
  actions: {
    async getAccountContractDepositList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-deposit/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountContractSettlementList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-settlement/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountContractExcessSettlementList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-excess-settlement/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountContractExcessSettlementMonthlyList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-excess-settlement-monthly/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getTenantList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/info/tenant`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getProductList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/info/product`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranchList (context) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/info/branch`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRoomPrice (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/info/room-price`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSeatPrice (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/info/seat-price`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract/${payload.contractId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContract (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getTenantTotalPrice (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-tenant-total-price/${payload.tenantId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postContractManage ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-manage`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postContractStatusManage ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-status-manage`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountMembershipContractInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/membership-contract`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getReservationCount (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-reservation-count/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractStatusManage (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-status-manage/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractRentalDepositPaymentStatus (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-rental-deposit-payment-status/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSuccessionContract (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/succession/contract/${payload.contractRegistrationKey}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postSuccessionContract ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/succession/contract`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postContractPrepayment ({ commit }, payload) {
      let response
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-prepayment`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getPrepayment (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract/${payload.contractId}/prepayment`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractPenalties (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract/${payload.contractId}/penalties`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractPenalty (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract/penalty/${payload.contractPenaltyId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postContractPenalty ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-penalty`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putContractPenalty ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-penalty/${payload.contractPenaltyId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteContractPenalty ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-penalty/${payload.contractPenaltyId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountCreditSettlementList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-credit-list/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractPdf (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-pdf/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
