const getDefaultState = () => {
  return {
    discountCodes: [],
    discountCode: {},
    originFilter: {},
    totalCount: 0,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalCount: (state) => state.totalCount,
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.totalCount || 0
      return Math.ceil(count / limit)
    },
    originFilter: (state) => state.originFilter,
    discountCode: (state) => state.discountCode,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setDiscountCodes (state, data) {
      state.discountCodes = data.couponCodes
      state.totalCount = data.totalCount
    },
    setDiscountCode (state, data) {
      state.discountCode = data
    },
  },
  actions: {
    async getDiscountCodes ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}coupons`, payload)
        commit('setDiscountCodes', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postDiscountCode ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}coupons`, payload)
        commit('setDiscountCode', response)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getDiscountCode ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}coupons/${payload.couponIssuanceId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putDiscountCode ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}coupons/${payload.couponIssuanceId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getDiscountCodeUseList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}coupons/${payload.couponIssuanceId}/${payload.couponCode}/use-list`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
