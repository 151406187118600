export default [
  // 공간대여 > 카테고리 관리
  {
    path: '/space-rental/category-list',
    name: 'RentalCategoryList',
    component: () => import('@/components/spacerental/category/list'),
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  // 공간대여 > 메인페이지 관리
  {
    path: '/space-rental/main-list',
    name: 'RentalMainList',
    component: () => import('@/components/spacerental/cms/SpaceReatalCmsList'),
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  // 공간대여 > 상세 관리
  {
    path: '/space-rental/cms-detail',
    name: 'RentalCmsDetail',
    component: () => import('@/components/spacerental/cms/SpaceRentalCmsDetail'),
    meta: {
      requiresAuth: false,
      fullScreen: false,
      pageTitle: '공간대여 컨텐츠 관리',
    },
  },
  // 공간대여 > 할인코드 관리
  {
    path: '/space-rental/discount-codes',
    name: 'DiscountCodes',
    component: () => import('@/components/spacerental/discount_code/DiscountCodes'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '할인코드 관리',
    },
  },
  // 공간대여 > 할인코드 등록
  {
    path: '/space-rental/discount-codes/regist',
    name: 'DiscountCodeRegistration',
    component: () => import('@/components/spacerental/discount_code/DiscountCodeRegistration'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '할인코드 등록',
    },
  },
]
