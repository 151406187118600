const getDefaultState = () => {
  return {
    list: null,
    summarylist: null,
    count: 0,
    originFilter: {},
    branchList: [],
    meetingRoomList: [],
    meetingRoomDetail: {},
    reservationTimeline: '',
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.applicationList || null
      state.summarylist = data.sumList || null
      state.count = data.totalCount || 0
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataGroup (state, data) {
      state.group = data || {}
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setMeetingRoomList (state, data) {
      state.meetingRoomList = data || []
    },
    setMeetingRoomDetail (state, data) {
      state.meetingRoomDetail = data || {}
    },
    setReservationTimeline (state, data) {
      state.reservationTimeline = data || {}
    },
  },
  actions: {
    // 예약현황 조회
    async getMeetingRoomList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/list`, payload)
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 분류 불러오기
    async getDbColsCodeList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}common/db-cols-code`, payload)
        // commit('setDataList', response.data.dbColsCodeList || [])
        // commit('setDataCount', response.data.dbColsCodeCount || 0)
        // commit('setDataGroup', response.data.dbColsCodeGroup || {})

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 엑셀 리스트 불러오기
    async getMeetingRoomRentalExcelList (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/excel`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 미팅룸 예약상세현황 불러오기
    async getRentalData ({ commit }, rentalApplicationId) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/detail?rentalApplicationId=${rentalApplicationId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 대관신청 수정
    async putMeetingRoom ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload.rentalApplicationId}`, payload.filteredData)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 대관신청 취소
    async putMeetingRoomCancellation ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload.rentalApplicationId}/cancellation?rentalCancellationRefundAmount=${payload.rentalCancellationRefundAmount}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    /**
     *
     *
     */
    async getRentalMeetingRoomData ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/able_meetingroom`, payload)
        commit('setMeetingRoomList', response.data.meetingrooms || [])
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRentalMeetingRoomDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload}`)
        commit('setMeetingRoomDetail', response.data || {})
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRentalMeetingRoomReservationTimeline ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/reservation_timeline`, payload)
        commit('setReservationTimeline', response.data || {})
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postRentalMeetingRoomReservationRegister ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putRentalMeetingRoomReservationGuestClose ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload.rentalApplicationId}/guests`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRentalMeetingRoomReservationGuests ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload.rentalApplicationId}/guests`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postRentalMeetingRoomReservationGuestExcelDownload ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}rental/meeting-room/application/${payload.rentalApplicationId}/guests/download`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
