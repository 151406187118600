<template>
  <div class="wrap">
    <div v-show="$store.state.requested" class="loading"></div>
    <div v-show="$store.state.requested" class="spinner-wrap">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>

    <transition name="component-fade" mode="out-in">
      <div v-if="$route.meta.fullScreen" class="content fullscreen">
        <router-view :key="$route.path"/>
      </div>
      <div v-else class="normal" :key="$route.path">
        <div v-show="versionNotice" @click="reload" class="version-notice">
          <transition name="slide-down">
            <div class="slide-content">
              🚀 업데이트가 있습니다. 클릭 시 새로고침 됩니다. 🚀
            </div>
          </transition>
        </div>
        <button v-if="!isFolded" style="position:fixed" type="button" class="toggle-nav" @click="toggleNavigation">
          <ion-icon name="menu-outline" class="icon"></ion-icon>
        </button>
        <navigation v-if="!isFolded" />
        <div class="page-wrap">
          <header class="header">
            <button  v-if="isFolded" type="button" class="toggle-nav" @click="toggleNavigation">
              <ion-icon name="menu-outline" class="icon"></ion-icon>
            </button>
            <div class="page-path-title">
              <div ref="menuFullName" class="full-path">{{ menuFullName }}</div>
              <div ref="pageTitle" class="title">{{ pageTitle }}</div>
            </div>
            <portal-target class="action-bar" name="action-bar" />
          </header>
          <main class="main">
            <router-view @totalPages="totalPages" :key="$route.path"/>
            <pagination style="flex-basis:content; margin-bottom:40px;" :totalPages="count"/>
          </main>
        </div>
      </div>
    </transition>
    <sp-modals></sp-modals>
  </div>
</template>
<script>
import aws from 'aws-sdk'
import Navigation from './Navigation'
import Pagination from '@/components/common/Pagination'
import SpModals from '@/components/common/SpModals'
export default {
  name: 'App',
  data () {
    return {
      fullscreen: true,
      count: 0,
      version: 0,
      versionNotice: false,
      versionInterval: null,
    }
  },
  mounted () {
    if (window.innerWidth > 1000) {
      this.$store.commit('toggleIsFolded')
    }

    if (location.href.indexOf('local') == -1) {
      this.recycleVersion()
    }
  },
  methods: {
    totalPages (count) {
      this.count = count
    },
    toggleNavigation () {
      this.$store.commit('toggleIsFolded')
    },
    recycleVersion () {
      this.getCurrentVersion()

      this.versionInterval = setInterval(() => {
        this.updateCurrentVersion()
      }, 5 * 60 * 1000) // 5분 (5 * 60 * 1000 밀리초)
    },
    // 현재 버전과 비교하여 업데이트
    updateCurrentVersion () {
      try {
        aws.config.update({
          region: process.env.VUE_APP_BUCKET_REGION,
          credentials: new aws.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
          }),
        })

        const s3 = new aws.S3()

        s3.getObject(
          {
            Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
            Key: 'production/version/crew-admin/' + process.env.VUE_APP_MODE + '.txt',
          }, (err, data) => {
            if (err) {
              console.error('Error reading S3 file:', err)
            } else {
              const currentVersion = parseInt(data.Body.toString())

              if (currentVersion != this.version) {
                this.version = currentVersion
                this.versionNotice = true

                clearInterval(this.versionInterval)
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    // 현재 어드민 버전 가져오기
    getCurrentVersion () {
      try {
        aws.config.update({
          region: process.env.VUE_APP_BUCKET_REGION,
          credentials: new aws.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
          }),
        })

        const s3 = new aws.S3()

        s3.getObject(
          {
            Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
            Key: 'production/version/crew-admin/' + process.env.VUE_APP_MODE + '.txt',
          }, (err, data) => {
            if (err) {
              console.error('Error reading S3 file:', err)
            } else {
              this.version = parseInt(data.Body.toString())
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    reload () {
      location.reload()
    },
  },
  components: {
    Navigation,
    Pagination,
    SpModals,
  },
  computed: {
    isFolded () {
      return this.$store.state.isFolded
    },
    // lnb () {
    // const parentMenuNo = this.$store.getters.pathToMenu(this.$route.path)?.parentMenuNo || 0
    // return this.$store.state.tree[parentMenuNo] || {}
    // },
    pageTitle () {
      return this.$route.meta.pageTitle || this.$store.getters.pathToMenu(this.$route.path)?.menuNm
    },
    menuFullName () {
      return this.$store.getters.pathToMenu(this.$route.path)?.menuFullNm
    },
    isProduction () {
      return process.env.VUE_APP_MODE === 'production'
    },
  },
  watch: {
    '$route.meta.fullScreen': {
      deep: true,
      immediate: true,
      handler: function (isFullscreen) {
        this.fullscreen = isFullscreen
      },
    },
  },
  created () {
    if (!this.isProduction) {
      document.title = `(${process.env.VUE_APP_MODE_KR ?? 'DEV'}) ${document.title}`
    }
  },
}
</script>
<style lang="scss">
/* 전역설정 */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");
:root {
  --theme-primary-color: #3D5AFE;
  --theme-secondary-color: #36B6B0;
  --theme-tertiary-color: #2956db;
  // --theme-primary-color: crimson;
}
.toggle-nav {
  background: #bbbbbb44;
  z-index: 999;
  border-radius: 10px;
  margin: 6px 7.5px;
  position: sticky;
  height: 40px;
  width: 40px;
  border: 0;
  .icon {
    color:#aaa;
    background-color: transparent;
    font-size: 30px;
  }
}
html, body{
  font-family: NanumSquareAc;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #333;
}
.loading {
  // animation: loading 1s infinite;
  opacity: 0.3;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  // height : 2200px;
  // -webkit-transform: translateY(100%);
  // transform: translateY(100%);
  z-index: 1000;
  position:fixed;
}
.fullscreen {
  width: 100%;
}
.normal {
  display: flex;
  min-height: 100%;
  align-content: stretch;
  width: 100%;
}

.wrap .main label {
  height: 34px;
  line-height: 34px;
  display: inline-block;
}
.right{
  float:right !important;
}
.container {
  // overflow: auto;
  // width:100%;
  // background-color: red;
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  font-size:14px;
  font-weight: 600;
  a {
    color: var(--theme-primary-color);
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  .filter, .counter {
    padding: 10px 0;
    // background-color: red;
    box-sizing: border-box;
    // padding: 0 4px;
    height: fit-content;
    line-height: 40px;
    flex-basis: 0;
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;
    .form-block {
      display: block;
      max-width: 100%;
      margin: 0;
    }
    .form-inline {
      display: inline-block;
      max-width: 100%;
      margin: 0;
    }
    label{
      line-height: 40px;
      box-sizing: border-box;
      margin: 0;
      min-width: 120px;
      padding: 0 10px;
      font-weight: 600;
      vertical-align: top;
      sup {
      line-height: normal;
      }
    }
    .inputbox+label {
      vertical-align: baseline;
      display: inline-block !important;
    }
    .inputbox>label {
      min-width: auto;
    }

  }
  .counter {
    border-bottom: none;
  }
  >.content {
    overflow: auto;
    background:
      linear-gradient(to right, #ffffff 33%, rgba(255,255,255, 0)),
      linear-gradient(to right, rgba(255,255,255, 0), #ffffff 66%),
      radial-gradient(farthest-side at left,  rgba(54,54,54, 0.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at right, rgba(54,54,54, 0.2), rgba(0,0,0,0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
    background-attachment: local, local, scroll, scroll;
  }
}
.horizontal-scroll-shadows {
  background:
    linear-gradient(to right, #ffffff 33%, rgba(255,255,255, 0)),
    linear-gradient(to right, rgba(255,255,255, 0), #ffffff 66%),
    radial-gradient(farthest-side at left,  rgba(54,54,54, 0.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at right, rgba(54,54,54, 0.2), rgba(0,0,0,0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
  background-attachment: local, local, scroll, scroll;
}
.no-data {
  display:flex;
  height:calc(100% - 41px);
  min-height:200px;
  // justify-content: stretch;
  // height: 500px;
  align-items:center;
  div {
    flex: 1;
    text-align:center
  }
}
.monospaced {
  font-family: 'Nanum Gothic Coding';
  font-size: 1.1em;
}
.code {
  background: #eee; border:1px solid #ddd; border-radius: 4px; padding: 5px;
  overflow: auto;
}
.panel {
  &.visible {
    overflow: visible;
  }
  overflow: auto;
  min-width:100%;
  max-width: fit-content;
  background: #fcfcfc77;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 10px;
  box-sizing: border-box;
  .icon {
    vertical-align: -.14em;
  }
  h4.title{
    padding:0; margin:10px 2px;
  }
  ul {
    margin: 10px 0;
    padding-inline-start: 20px;
    li:not(:last-child) {
      margin-bottom: 3px;
    }
    li::marker {
      color: #111;
      font-size: 1px;
      list-style-type: circle;
    }
  }
  table{
     table-layout: fixed;
     width: 100%;
  }
  th {
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    span {
      color:red
    }
  }
}
.submit-bar {
  margin: 20px auto 40px auto;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 0px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}
.file-label {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 32px !important;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 0 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  input {
    + span {
      cursor: pointer;
    }
    &:disabled + span {
      color: #808080;
      cursor: not-allowed;
    }
  }
}
@keyframes loading {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(3px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}
</style>
<style lang="scss" scoped>
.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  .page-wrap {
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .header {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 52px 36px 32px 36px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 130px;
      // padding: 0 10px;
      border-bottom: 1px solid #d0d0d0;
      .toggle-nav {
        transform: translate(-36px, -52px);
      }
      .toggle-nav + .page-path-title {
        transform: translateX(-40px);
      }
      .page-path-title {
        flex-basis: 100%;
        .full-path {
          color: #BDBDBD;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
        }
        .title {
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
        }
      }
      .action-bar {
        flex: 0 0 auto;
      }
    }
    .main {
      background-color: #fffffff1;
      // border-bottom: 1px solid #d0d0d0;
    }
  }

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition:
      opacity .3s linear,
      filter  .3s linear,
    ;
  }
  .component-fade-enter,
  .component-fade-leave-to {
    opacity: .95;
    filter: blur(2px);
  }
  $offset: 187;
  $duration: 1.4s;
  .spinner-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }
  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
  }
  @keyframes colors {
    0% { stroke: #4285F4; }
    25% { stroke: #DE3E35; }
    50% { stroke: #F7C223; }
    75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
  }
  @keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
      stroke-dashoffset: $offset/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }

  /deep/ {
    .multiselect {
      display: inline-block;
      box-sizing: border-box;
      margin: 4px 0;
      padding:0  2.5px;
      line-height: 24px;
      min-width: 60px;
      min-height: 24px;
      vertical-align: middle;
      &--disabled {
        background: transparent;
        .multiselect__select {
          background: transparent;
        }
        .multiselect__single {
          background: #f6f6f6;
          color: #000;
           height: 24px;
          padding: 2px 0 0 5px;
        }
        .multiselect__tags {

          background: #f6f6f6;
        }
        .multiselect__placeholder {
          color: #000;
        }
      }
    }

    .multiselect__select {
      right: -6px;
      top: 2px;
      line-height: 24px;
      height: 24px;
      &:before {
        border-color: var(--theme-primary-color) transparent transparent;
        top: 7px;
        border-style: solid;
        border-width: 5px 5px 0;
      }
    }
    .multiselect__tag {
      background-color: var(--theme-primary-color);
      margin: 2.5px;
      vertical-align: top;
      height: 20px;
    }
    .multiselect__tag-icon {
      &:hover {
        background: var(--theme-primary-color);
      }
      &:after {
        color: #fff;
      }
    }
    .multiselect__tags {
      padding: 0;
      min-height: 22px;
      margin: 0;
      border-color: #ddd;
      box-sizing: border-box;
    }
    .multiselect--active {
      .multiselect__tags .multiselect__input {
        // margin-top: 5px;
      }
      .multiselect__tags-wrap {
        overflow: initial;
        height: 22px;
      }
    }
    .multiselect__tags-wrap {
      position: relative;
      // top: 2px;
      overflow: auto;
      height: fit-content;
      display: inline-block;
      width: calc(100% - 24px);
    }
    .multiselect__input {
      width: auto;
      font-size: 14px;
      line-height: 24px;
      text-indent: 0px;
      margin-bottom: 0;
    }
    .multiselect__placeholder {
      font-size: 14px;
      line-height: 24px;
      padding: 0;
      margin: 0;
      text-indent: 5px;
      height: 24px !important;
      overflow: hidden;
      display: block !important;
      text-overflow: ellipsis;
      font-weight: normal;
    }
    .multiselect__option {
      padding: 0 10px;
      height: 24px;
      min-height: 0;
      line-height: 24px;
      font-size: 14px;
    }
    .multiselect__option--highlight::after, .multiselect__option--selected::after {
      height: 24px;
      min-height: 0;
      line-height: 24px;
    }
    .multiselect__content-wrapper{
      min-width: 150px;
      width: calc(100% - 5px);
      padding: 0 2.5px;
      box-sizing: border-box;
      ul {
        padding-inline-start: 0;
        li {
          width: 100%;
          overflow: hidden;
        }
      }
    }
    .multiselect__option--highlight {
      background-color: var(--theme-primary-color);
      &::after {
        background-color: var(--theme-primary-color);
      }
    }
    .multiselect__single {
      font-weight: normal;
      font-size: 14px;
      margin: 1px 0;
      height: 22px;
      line-height: 22px;
      overflow: hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }
  }
  /deep/ .ck-editor {
    .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
      height: 14px;
    }
  }
  .version-notice{
    z-index: 10;
    position: fixed;
    background-color: #2cb6af;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 10px;
    animation: showfadeout 5s normal;
    opacity: 1;
    cursor: pointer;
  }
  @keyframes showfadeout {
    50% {
      transition: transform 3s;
      transform: translateY(100%);
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 600px) {

  .container {
    padding: 0 7.5px;
  }

  .wrap {
    .normal {
      display: block;
      min-height: 100%;
      align-content: initial;
      width: 100%;
    }
    .navigation + .page-wrap {
      display: none;
    }
    .page-wrap {
      overflow: initial;
      .header {
        position: sticky;
        background: #fff;
        top:0;
        z-index: 900;
        flex-basis: initial;
        padding: 6px 7.5px;
        .toggle-nav {
          margin : 0;
          transform: translate(0,0);
        }
        .toggle-nav + .page-path-title {
          transform: translateX(0);
        }
        .page-path-title {
          flex-basis: 100%;
          overflow: hidden;
          .full-path {
            padding: 4px 5px;
            color: #BDBDBD;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title {
            padding: 0 5px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .action-bar {
          flex: 1 1 auto;
          text-align: right;
        }
      }
      .main {
        background-color: #fffffff1;
        // border-bottom: 1px solid #d0d0d0;
        /deep/ .filter label {
          display: block;
        }
        /deep/ .filter .inputbox>label {
          display: inline-block;
        }
      }
    }
  }

}
</style>
